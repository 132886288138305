import './App.css';
import React from 'react';
function App() {

    return (
        <div className="App">
            <header className="App-header">
                <p>
                    <h1 className='name-header'>Cade Archer</h1>
                    <hr className='hl-header' />
                    <h2 className='name-header'>Software Engineer</h2>

                </p>
            </header>
            <body className='App-body'>
                <div className="contact-jump"> <a className="body-link" href="#Contact">Jump to Contact</a></div>
                <div className='section-div'><h3 className='section-header'>Resume</h3></div>

                <p className="display">
                    <a
                        className='body-link'
                        href="https://docs.google.com/document/d/e/2PACX-1vQyAMlzCh4qdQcxZo3UQCx8iT3EdCYgHSNEjwtXXX9TnUXw_WduhYVVpOD6qnv7OBhpjG6ls-IGijhl/pub"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Open in Separate Window
                    </a>
                    <iframe
                        title="resume"
                        style={{ maxWidth: 640, width: '100%', height: '1400px', overflow: 'visible' }}
                        src="https://docs.google.com/document/d/e/2PACX-1vQyAMlzCh4qdQcxZo3UQCx8iT3EdCYgHSNEjwtXXX9TnUXw_WduhYVVpOD6qnv7OBhpjG6ls-IGijhl/pub?embedded=true"
                        width="100%"
                        scrolling="no"
                        frameBorder="0"
                        className="display"
                    />

                </p>

                <div className='section-div'><h3 className='section-header'>College Projects</h3></div>
                <p className="display">
                    <h4 className='name-header'>Replace All Humans: How Mass Technological Unemployment is Becoming a Reality</h4>
                    <hr className='hl-body' />
                    <p>This was my final project for the
                        <a
                            className="body-link"
                            href="http://catalog.sfasu.edu/preview_course_nopop.php?catoid=1&coid=1536"
                            target="_blank"
                            rel="noopener noreferrer"> CSC 411 - Ethics in Computer Science course
                        </a> at Stephen F. Austin University. You can view the associated paper
                        <a
                            className="body-link"
                            href="https://docs.google.com/document/d/e/2PACX-1vToZmmDOvniQAzi6OGhK2S0o9NerA32m7a8OPQFAy2NHlErLPXNq4_1djexBi_F6w/pub"
                            target="_blank"
                            rel="noopener noreferrer"
                        > here</a>
                    </p>
                    <a
                        className='body-link'
                        href="https://docs.google.com/presentation/d/e/2PACX-1vSddPCclQ-jgkPFqZPL0kUy-NWeQe0pHlrqubyCBZS7BHqNf1tWI_Hi6qlUBU8WEQ/pub?start=true&loop=true&delayms=5000"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View Slides
                    </a>
                    <iframe
                        title="CSC411"
                        src="https://docs.google.com/presentation/d/e/2PACX-1vSddPCclQ-jgkPFqZPL0kUy-NWeQe0pHlrqubyCBZS7BHqNf1tWI_Hi6qlUBU8WEQ/embed?start=true&loop=true&delayms=3000"
                        frameborder="0"
                        width="480"
                        height="299"
                        allowfullscreen="true"
                        mozallowfullscreen="true"
                        webkitallowfullscreen="true"
                        className="display">
                    </iframe>
                    <h4 className='name-header'>Finance Tracker</h4>
                    <hr className='hl-body' />
                    <p>This was a group project for the
                        <a
                            className="body-link"
                            href="https://www.coursicle.com/syracuse/courses/CSE/682/"
                            target="_blank"
                            rel="noopener noreferrer"> CSE 682 - Software Engineering course
                        </a> at Syracuse University. The purpose was to create a software requirements specification (SRS) for a fictional project. Along with the presentation, a web app showing off initial capability was developed and can be accessed
                        <a
                            className="body-link"
                            href="https://financetracker-bb7dd.firebaseapp.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        > here</a>
                    </p>
                    <a
                        className='body-link'
                        href="https://docs.google.com/presentation/d/e/2PACX-1vQ6Lpw390BeoVegdJ0VYIXXdwx7FgVfycENU1mbRm_E5-KC2oe_H29ayHeinFRH7LFxkVADeaG3Go4G/pub?start=true&loop=true&delayms=5000"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View Slides
                    </a>
                    <iframe
                        src="https://docs.google.com/presentation/d/e/2PACX-1vQ6Lpw390BeoVegdJ0VYIXXdwx7FgVfycENU1mbRm_E5-KC2oe_H29ayHeinFRH7LFxkVADeaG3Go4G/embed?start=true&loop=true&delayms=3000"
                        frameborder="0"
                        width="480"
                        height="299"
                        allowfullscreen="true"
                        mozallowfullscreen="true"
                        webkitallowfullscreen="true"
                        className="display"
                        title="CSE682">
                    </iframe>
                    <h4 className='name-header'>IMDB Rating &amp; Revenue Prediction Model</h4>
                    <hr className='hl-body' />
                    <p>This was a group project for the
                        <a
                            className="body-link"
                            href="https://www.coursicle.com/syracuse/courses/CSE/600/"
                            target="_blank"
                            rel="noopener noreferrer"> CIS 600 - Intro to Data Mining course
                        </a> at Syracuse University. In this project we created an ML model to predict revenue and user review scores of movies. The research paper associated with the presentation can be found
                        <a
                            className="body-link"
                            href="https://docs.google.com/document/d/e/2PACX-1vS7tITxSWUc99INPp85I6_g8JnQLjZs3hsjYiR3pNhg6dnKiYw5kFdq5zUN59jNHw/pub"
                            target="_blank"
                            rel="noopener noreferrer"
                        > here</a>
                    </p>
                    <a
                        className='body-link'
                        href="https://docs.google.com/presentation/d/e/2PACX-1vSi0oXxt2KuHIxmhkcHuxrUTRbZQ4if6WBRV6-VCvV0Km3supYoagcl5cXI0T5fBA/pub?start=true&loop=true&delayms=5000"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View Slides
                    </a>
                    <iframe
                        src="https://docs.google.com/presentation/d/e/2PACX-1vSi0oXxt2KuHIxmhkcHuxrUTRbZQ4if6WBRV6-VCvV0Km3supYoagcl5cXI0T5fBA/embed?start=true&loop=true&delayms=3000"
                        frameborder="0"
                        width="480"
                        height="299"
                        allowfullscreen="true"
                        mozallowfullscreen="true"
                        webkitallowfullscreen="true"
                        className="display"
                        title="CIS600">
                    </iframe>
                    <h4 className='name-header'>Emulating Quantum Computing Systems Using FPGA</h4>
                    <hr className='hl-body' />
                    <p>This was my final project for the
                        <a
                            className="body-link"
                            href="https://www.coursicle.com/syracuse/courses/CIS/655/"
                            target="_blank"
                            rel="noopener noreferrer"> CIS 655 - Advanced Computer Architecture
                        </a> at Syracuse University. The goal of the project was to research an area of current architecture development. The paper associated with the presentation can be found
                        <a
                            className="body-link"
                            href="https://docs.google.com/document/d/e/2PACX-1vRG6-qsdCmvYIF_U4Sno-m7w1YdMLs9oHoL89HmfBexjshKReCOkVXIj5JzaG_KIg/pub"
                            target="_blank"
                            rel="noopener noreferrer"
                        > here</a>
                    </p>
                    <a
                        className='body-link'
                        href="https://docs.google.com/presentation/d/e/2PACX-1vQsuoWA9JlYGug6oJZBQyLFc4lkisrY3PUCzFhNzks2sJGEA1zVy_Un3COHwAhJ9Q/pub?start=true&loop=true&delayms=5000"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View Slides
                    </a>
                    <iframe
                        src="https://docs.google.com/presentation/d/e/2PACX-1vQsuoWA9JlYGug6oJZBQyLFc4lkisrY3PUCzFhNzks2sJGEA1zVy_Un3COHwAhJ9Q/embed?start=true&loop=true&delayms=3000"
                        frameborder="0"
                        width="480"
                        height="299"
                        allowfullscreen="true"
                        mozallowfullscreen="true"
                        webkitallowfullscreen="true"
                        className="display"
                        title="CIS655">
                    </iframe>
                </p>

            </body>
            <footer className="App-footer">
                <h1 className='name-header'><a id="Contact">Contact</a></h1>
                <hr className='hl-header' />
                <p>Email: <a className="footer-link" href="mailto:contact@cade.works">contact@cade.works</a></p>
                <a
                    className="footer-link"
                    href="https://linkedin.com/in/cade-archer-7411a6148"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    LinkedIn Profile
                </a>
                <br></br>
            </footer>
        </div >
    );
}

export default App;
